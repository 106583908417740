<template>
	<div class="refine_search" @click="changeText">
		<text-tooltip ref="searchTooltip" :text="tooltipText" />
	</div>
</template>

<script>
import TextTooltip from "@/components/Tooltips/TextTooltip.vue";
export default {
	components: {
		TextTooltip
	},

	data() {
		return {
			tooltipShowed: false,
			tooltipText: "Refine your search"
		};
	},

	mounted() {
		window.addEventListener("scroll", this.hideOnScroll);
	},

	beforeUnmount() {
		window.removeEventListener("scroll", this.hideOnScroll);
	},

	methods: {
		hideOnScroll() {
			const header = document.querySelector(".refine_search");
			if (scrollY >= 1200) {
				header.classList.add("show");
				if (!this.tooltipShowed) {
					this.$refs.searchTooltip.show();
					this.tooltipShowed = true;
				}
			} else {
				header.classList.remove("show");
				this.$refs.searchTooltip.hide();
				this.tooltipShowed = false;
				this.tooltipText = "Refine your search";
			}
		},
		changeText() {
			this.tooltipText = "Choose a product";
			this.$refs.searchTooltip.show();
			this.tooltipShowed = true;
		}
	}
};
</script>

<style lang="scss" scoped>
.refine_search {
	position: fixed;
	bottom: 2em;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 12px;
	width: 3.063em;
	height: 3.063em;
	cursor: pointer;
	left: 0;
	transform: translateX(-100%);
	background-color: #e53238;
	z-index: 999;
	transition: all 0.3s ease-in-out;
	&.show {
		left: 1em;
		transform: translateX(0);
	}
	&.anim {
		&:after {
			transform: translate(-50%, -50%) rotate(360deg);
			-webkit-transform: translate(-50%, -50%) rotate(360deg);
		}
	}
	&:after {
		content: "";
		position: absolute;
		display: block;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 1.875em;
		height: 1.875em;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: contain;
		backface-visibility: hidden;
		background-image: url("@/assets/images/svg/marker.svg");
		transition: all 700ms ease-in-out;
	}
	.tooltip {
		top: -1.1em;
		left: calc(100% + 2.1em);
		&:after {
			left: 26px;
		}
	}
}
</style>
