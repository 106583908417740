<template>
	<transition name="show">
		<div class="tooltip" v-if="opened">
			<span>{{ text }}</span>
		</div>
	</transition>
</template>

<script>
export default {
	props: {
		text: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			opened: false
		};
	},

	methods: {
		show() {
			this.opened = true;
			setTimeout(() => {
				this.hide();
			}, 4000);
		},

		hide() {
			this.opened = false;
		}
	}
};
</script>

<style lang="scss" scoped>
.tooltip {
	position: absolute;
	top: -1.1em;
	left: 50%;
	transform: translate(-50%, -100%);
	padding: 0.75em 1.125em;
	background-color: black;
	border-radius: 6px;
	white-space: nowrap;
	backface-visibility: hidden;
	will-change: transform opacity;
	transition: $defaultTransition;
	&.show-enter-active,
	&.show-leave-active {
		opacity: 0;
		transform: translate(-50%, -90%);
	}
	span {
		display: block;
		font-size: 0.875em;
		line-height: 1;
	}
	&:after {
		content: "";
		position: absolute;
		top: calc(100% - 1px);
		left: 50%;
		margin-left: -9px;
		border-width: 9px;
		border-style: solid;
		border-color: black transparent transparent transparent;
	}
}
</style>
