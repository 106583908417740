<template>
	<header class="header" :class="header" @click="openScanner()">
		<div class="dots"></div>
		<router-link to="/" class="logo">
			<img src="@/assets/images/svg/logo.svg" alt="" />
		</router-link>
		<div class="info"></div>
		<!-- <div class="requests_left">{{ requestsLeft }}</div> -->
	</header>
</template>

<script>
import { mapState } from "vuex";
export default {
	computed: mapState(["header"]),

	created() {
		//this.$store.commit("SET_HEADER_STATE", "hide_no_scroll");
	},

	mounted() {
		if (window.innerWidth < 768) {
			window.addEventListener("scroll", this.hideOnScroll);
		}
	},

	beforeUnmount() {
		window.removeEventListener("scroll", this.hideOnScroll);
	},

	methods: {
		hideOnScroll() {
			if (scrollY >= 200) {
				this.$store.commit("SET_HEADER_STATE", "hide");
			} else {
				this.$store.commit("SET_HEADER_STATE", "show");
			}
		},

		openScanner() {
			this.emitter.emit("openScanner");
		}
	}
};
</script>

<style lang="scss" scoped>
.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.688em 1.125em;
	background-color: white;
	border-bottom: 1px solid #e9e9e9;
	box-shadow: 2.5px 4.33px 18px 0px rgba(0, 0, 0, 0.06);
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
	z-index: 99999999999;
	transition: $defaultTransition;
	&.hide {
		transform: translateY(-120%);
	}
	&.hide_no_scroll {
		transform: translateY(-120%) !important;
	}
	&.show_no_scroll {
		transform: translateY(0) !important;
	}
}
.dots {
	width: 1.5em;
	height: 1.5em;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: contain;
	background-image: url("~@/assets/images/svg/dots.svg");
}
.logo {
	width: 42%;
	line-height: 0;
	img {
		object-fit: contain;
	}
}
.info {
	width: 1.625em;
	height: 1.625em;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: contain;
	background-image: url("~@/assets/images/svg/info.svg");
}
.requests_left {
	position: absolute;
	right: 1em;
	top: 50%;
	transform: translateY(-50%);
	color: black;
	font-weight: 500;
	font-size: 1.313em;
}
</style>
