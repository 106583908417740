export const prepareProducts = (products) => {
	products = clearDublicate(products);
	products = sortProducts(products);
	return products;
};

export const clearDublicate = (products) => {
	let clearedBySourceProducts = new Map();
	let clearedByLinkProducts = new Map();
	for (let i = 0; i < products.length; i++) {
		clearedBySourceProducts.set(
			products[i].source + products[i].price,
			products[i]
		);
		//clearedByLinkProducts.set(products[i].link, products[i]);
	}
	for (let entry of clearedBySourceProducts) {
		clearedByLinkProducts.set(entry[1].link, entry[1]);
	}
	clearedByLinkProducts = Array.from(clearedByLinkProducts.values());
	return clearedByLinkProducts;
};

export const sortProducts = (products) => {
	let sortedProducts = products.sort((a, b) => {
		return parseFloat(a.price) - parseFloat(b.price);
	});
	return sortedProducts;
};
