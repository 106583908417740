<template>
	<div class="page_content">
		<div
			class="filter_container swiper-container"
			v-if="products.all.length"
			:class="{ search_opened: filterOpened }"
		>
			<div class="filters swiper-wrapper">
				<div class="filter swiper-slide search" @click="openSearch()"></div>
				<form
					class="search fixed_search"
					@submit.prevent="searchByName($event)"
				>
					<text-tooltip ref="searchTooltip" text="Enter code or name" />
					<div class="close" @click="closeSearch()"></div>
					<input
						id="fixedInput"
						autocomplete="off"
						autofocus
						name="search"
						type="text"
						placeholder="Search..."
						v-model="search"
					/>
					<div class="success" @click="searchByName()"></div>
				</form>
				<div
					class="filter swiper-slide"
					:key="index"
					v-for="(filter, index) in filters"
					:class="[
						{ active: currentFilter == filter.value },
						filter.value,
						{ with_icon: filter.icon }
					]"
					@click="currentFilter = filter.value"
				>
					<img
						:src="
							require('@/assets/images/svg/filter/' + filter.value + '.svg')
						"
						class="icon"
						v-show="filter.icon"
					/>
					<span class="title" v-show="!filter.icon">{{ filter.title }} </span>
					<span class="quantity" v-if="products[filter.value]">
						{{ products[filter.value].length }}
					</span>
					<span class="quantity" v-else> 0 </span>
				</div>
			</div>
		</div>
		<product-list
			v-if="
				typeof products[currentFilter] !== 'undefined' &&
				products[currentFilter].length
			"
			:products="products[currentFilter]"
			:selectedFilter="currentFilter"
			ref="productList"
		/>
		<div class="no_result" v-show="noResultShowed">
			<div class="icon">
				<img src="@/assets/images/svg/no_results.svg" alt="" />
			</div>
			<span class="title">No products</span>
			<div class="buttons">
				<div
					class="button open_scanner search_by_barcode"
					@click="openScanner('barcode')"
				>
					<div class="icon"></div>
					<span>To scanner</span>
				</div>
			</div>
		</div>
		<modal-scanner
			ref="modalScanner"
			@updateProducts="updateProducts($event)"
			@showNoResult="showNoResult()"
			@openSearchHistory="openSearchHistory()"
			@showTooltip="showTooltip($event)"
			@updatePagination="updatePagination($event)"
		/>
		<search-history
			ref="searchHistory"
			@searchFromHistory="searchFromHistory($event)"
		/>
		<open-scanner @openScanner="openScanner('barcode')" ref="openScanner" />
		<arrow-top
			v-if="typeof products.all !== 'undefined' && products.all.length"
		/>
		<refine-search
			v-if="typeof products.all !== 'undefined' && products.all.length"
		/>
		<searching-animation ref="searchingAnimation" />
		<!-- <outside-resource ref="outsideResource" /> -->
	</div>
</template>

<script>
import { prepareProducts } from "@/plugins/prepareProducts.js";
import ProductList from "@/components/Products/ProductList.vue";
import OpenScanner from "@/components/Buttons/OpenScanner.vue";
import ModalScanner from "@/components/Modals/ModalScanner.vue";
import SearchHistory from "@/components/Modals/SearchHistory.vue";
import TextTooltip from "@/components/Tooltips/TextTooltip.vue";
import ArrowTop from "@/components/Buttons/ArrowTop.vue";
import RefineSearch from "@/components/Buttons/RefineSearch.vue";
import SearchingAnimation from "@/components/Lottie/SearchingAnimation.vue";
import { Swiper } from "swiper";
import "swiper/swiper.min.css";
//import OutsideResource from "@/components/Modals/OutsideResource.vue";
export default {
	components: {
		ProductList,
		OpenScanner,
		ModalScanner,
		SearchHistory,
		TextTooltip,
		ArrowTop,
		RefineSearch,
		SearchingAnimation
	},

	data() {
		return {
			filters: [
				{
					title: "Products",
					value: "all",
					icon: false
				},
				{
					title: "Google",
					value: "shop",
					icon: true
				},
				{
					title: "Реклама",
					value: "advert",
					icon: true
				},
				{
					title: "Amazon",
					value: "amazon",
					icon: true
				},
				{
					title: "Ebay",
					value: "ebay",
					icon: true
				}
			],
			filterIcons: [],
			currentFilter: "all",
			products: {
				all: [],
				shop: [],
				advert: [],
				ebay: [],
				amazon: []
			},
			//isScanned: false,
			showListProducts: [],
			search: "",
			swiper: null,
			noResultShowed: false,
			filterOpened: false
		};
	},

	methods: {
		openScanner(type) {
			setTimeout(() => {
				this.$refs.searchHistory.close();
			}, 450);
			this.$refs.modalScanner.openScanner(type, true);
		},

		updateProducts(products) {
			for (let key in products) {
				this.products[key] = prepareProducts(products[key]);
				this.products.all = this.products.all.concat(this.products[key]);
			}
			this.products.all = prepareProducts(this.products.all);
			if (this.products.all.length) {
				this.$refs.openScanner.show();
			}
			if (!this.swiper) {
				this.swiper = new Swiper(".swiper", {
					slidesPerView: "auto",
					freeMode: true
				});
			}
			// else {
			// 	setTimeout(() => {
			// 		this.swiper.updateProgress();
			// 		this.swiper.update();
			// 		this.swiper.updateSlides();
			// 		this.swiper.updateSize();
			// 		this.swiper.slideTo(0, 500);
			// 	}, 400);
			// }
		},

		showNoResult() {
			this.$refs.openScanner.hide();
			this.noResultShowed = true;
			this.products = {
				all: [],
				shop: [],
				advert: [],
				ebay: [],
				amazon: []
			};
		},

		openSearchHistory() {
			this.$refs.searchHistory.open();
			this.$refs.openScanner.show();
		},

		async searchFromHistory(search) {
			this.$refs.searchingAnimation.showAnimation();
			setTimeout(() => {
				this.$refs.searchingAnimation.hideAnimation();
			}, 1000);
			await this.$refs.modalScanner.searchBy(search);
		},

		showTooltip(ref) {
			if (this.$refs[ref]) {
				this.$refs[ref].show();
			}
		},

		async searchByName() {
			this.$refs.searchingAnimation.showAnimation();
			setTimeout(() => {
				this.$refs.searchingAnimation.hideAnimation();
				this.closeSearch();
			}, 6000);
			await this.$refs.modalScanner.searchBy(this.search);
		},

		openSearch() {
			document.querySelector(".filters .fixed_search").classList.add("show");
			this.showTooltip("searchTooltip");
			this.filterOpened = true;
		},

		closeSearch() {
			if (document.querySelector(".filters .fixed_search")) {
				document
					.querySelector(".filters .fixed_search")
					.classList.remove("show");
			}
			this.filterOpened = false;
		},

		updatePagination() {
			if (this.products.all.length) {
				this.$refs.productList.updatePagination();
			}
		}
	},

	mounted() {
		// window.addEventListener("message", (message) => {
		// 	console.log(message.data);
		// 	if (message.data === "asdasd") {
		// 		alert("t");
		// 	}
		// 	if (message.data.type === "scanned") {
		// 		alert(message.data.value);
		// 	}
		// });
		// if (window.ReactNativeWebView) {
		// 	window.ReactNativeWebView.postMessage(JSON.stringify("test"));
		// }
		//this.$refs.modalScanner.openScanner("barcode", false);
	}
};
</script>

<style lang="scss" scoped>
.page_content {
	padding: 51px 0 0;
	overflow-x: hidden;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
	.filter_container {
		margin-top: 1em;
		overflow: hidden;
		width: 100%;
		&.search_opened {
			.filter {
				opacity: 0;
			}
		}
	}
	.filters {
		position: relative;
		display: flex;
		align-items: center;
		padding: 0 3em 4.5em 1em;
		overflow-x: auto;
		max-width: 100%;
		-ms-overflow-style: none;
		scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none;
		}
	}
	.fixed_search {
		position: absolute;
		top: 2px;
		left: 1em;
		width: 0;
		height: 2.663em;
		display: flex;
		align-items: center;
		background-color: white;
		border: 1px solid #e1e1e1;
		padding-left: 0.4em;
		border-radius: 50px;
		opacity: 0;
		visibility: hidden;
		transition: $defaultTransition;
		z-index: -2;
		&.show {
			width: calc(100vw - 2em);
			opacity: 1;
			visibility: visible;
			z-index: 2;
			.success {
				opacity: 1;
				transition: all 400ms 700ms ease-in-out;
			}
		}
		:deep() {
			.tooltip {
				top: 100%;
				transform: translate(-50%, 40%);
				&:after {
					top: 0;
					transform: translateY(calc(-100% + 1px)) rotate(180deg);
				}
			}
		}
		input {
			padding: 0.7em 2.5em 0.7em 0.5em;
			color: black;
			font-weight: 500;
		}
		.close {
			position: relative;
			width: 2.25em;
			height: 2.25em;
			&:after {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				width: 1em;
				height: 1em;
				transform: translate(-50%, -50%);
				background-repeat: no-repeat;
				background-position: 50% 50%;
				background-size: contain;
				background-image: url("@/assets/images/svg/arrow.svg");
				filter: invert(1);
			}
		}
		.success {
			position: absolute;
			top: 50%;
			right: 0.3em;
			width: 2.125em;
			height: 2.125em;
			transform: translateY(-50%);
			opacity: 1;
			background-color: $green;
			border-radius: 50%;
			background-image: none;
			z-index: 9;
			opacity: 0;
			transition: all 400ms ease-in-out;
			&:after {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				width: 0.913em;
				height: 0.913em;
				transform: translate(-50%, -50%);
				background-repeat: no-repeat;
				background-position: 50% 50%;
				background-size: contain;
				background-image: url("@/assets/images/svg/check.svg");
				filter: brightness(0) invert(1);
			}
		}
	}
	.filter {
		display: flex;
		align-items: center;
		width: auto;
		margin-right: 0.5em;
		padding: 0.6em 1em 0.5em;
		border: 1px solid transparent;
		color: black;
		border-radius: 50px;
		white-space: nowrap;
		transition: $defaultTransition;
		&.active {
			border-color: #e1e1e1;
		}
		&.search {
			position: relative;
			padding: 0;
			width: 2.563em;
			height: 2.563em;
			border-color: #e1e1e1;
			&:after {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				width: 1.375em;
				height: 1.375em;
				transform: translate(-50%, -50%);
				background-repeat: no-repeat;
				background-position: 50%;
				background-size: contain;
				background-image: url("@/assets/images/svg/loop.svg");
				filter: brightness(0);
			}
		}
		.icon {
			width: 4.4em;
			height: 1.7em;
			max-width: unset;
			line-height: 0;
		}
		.quantity {
			padding-left: 0.4em;
		}
	}
	.product_list {
		padding: 0 0.5em 2em;
		margin-top: -3em;
	}
	.no_result {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		height: calc(100vh - 128px);
		.icon {
			width: 9.2em;
		}
		.title {
			color: #d5d5d5;
			font-size: 2.4em;
			font-weight: 600;
			margin-top: 0.225em;
		}
		.buttons {
			margin-top: 4em;
		}
		.button {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50px;
			padding: 0.7em 2em 0.7em 1.4em;
			margin-bottom: 1.2em;
			color: white;
			text-align: center;
			.icon {
				width: 1.313em;
				height: 1.313em;
				margin-right: 0.5em;
				background-repeat: no-repeat;
				background-position: 50%;
				background-size: contain;
			}
			&.search_by_barcode {
				background-color: $green;
				.icon {
					background-image: url("@/assets/images/svg/barcode_button.svg");
				}
			}
			&.search_by_name {
				background-color: $green;
				.icon {
					background-image: url("@/assets/images/svg/loop.svg");
				}
			}
		}
	}
	.searching {
		position: fixed;
		background-color: white;
		:deep() {
			.animation {
				left: calc(50% - 2em);
				svg {
					filter: invert(0);
				}
			}
		}
	}
}
</style>
