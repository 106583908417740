<template>
	<transition name="show">
		<div class="modal search_history" v-show="opened">
			<div class="content">
				<div class="titles">
					<span class="title">History codes</span>
					<span class="count">{{ searchHistory.list.length }}</span>
				</div>
				<div class="history_list" :class="{ safari: device.ios() }">
					<div
						class="item"
						:key="item.search"
						v-for="item in searchHistory.list"
						@click="search(item.search)"
					>
						<div class="image">
							<img :src="item.thumbnail" alt="" />
						</div>
						<div class="info">
							<span class="barcode">{{ item.search }}</span>
							<span class="name">{{ cutTitle(item.title) }}</span>
							<div class="date_block">
								<span class="date">{{ item.date }}</span>
								<span class="time">{{ item.time }}</span>
							</div>
						</div>
						<div
							class="remove icon"
							@click.stop="removeFromHistory(item.search)"
						></div>
						<div class="update icon"></div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapState } from "vuex";
import { disableScroll, enableScroll } from "@/plugins/scrollLock.js";
import device from "current-device";
export default {
	data() {
		return {
			opened: false,
			device: device
		};
	},

	computed: {
		...mapState(["searchHistory"])
	},

	methods: {
		open() {
			this.opened = true;
			setTimeout(() => {
				disableScroll(device.ios());
			}, 300);
		},

		close() {
			this.opened = false;
			this.$store.commit("SET_HEADER_STATE", "show");
			enableScroll(document.querySelector(".search_history"));
		},

		async search(search) {
			await this.$emit("searchFromHistory", search);
			setTimeout(() => {
				this.close();
			}, 400);
		},

		cutTitle(title) {
			return title.slice(0, 30);
		},

		removeFromHistory(search) {
			if (confirm("Delete from History?")) {
				this.$store.commit("REMOVE_FROM_HISTORY", search);
			}
			if (!this.searchHistory.list.length) {
				this.close();
			}
		},

		hideOnScroll() {
			if (document.querySelector(".search_history .content").scrollTop >= 200) {
				this.$store.commit("SET_HEADER_STATE", "hide");
			} else {
				this.$store.commit("SET_HEADER_STATE", "show");
			}
		}
	},

	created() {
		this.emitter.on("openScanner", () => {
			setTimeout(() => {
				if (this.opened) {
					this.close();
				}
			}, 300);
		});
	},

	mounted() {
		if (window.innerWidth < 768) {
			document
				.querySelector(".search_history .content")
				.addEventListener("scroll", this.hideOnScroll);
		}
	},

	beforeUnmount() {
		document
			.querySelector(".search_history .content")
			.removeEventListener("scroll", this.hideOnScroll);
		this.emitter.off("openScanner");
	}
};
</script>

<style lang="scss" scoped>
.search_history {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: white;
	transition: all 300ms ease;
	z-index: 97;
	&.show-enter-active,
	&.show-leave-active {
		opacity: 0;
		transform: translateY(20%);
	}
}
.content {
	padding-top: 47px;
	height: 100%;
	overflow: auto;
}
.titles {
	color: black;
	display: flex;
	justify-content: space-between;
	//padding: 0.813em 1em;
	padding: 1em;
	// border-bottom: 1px solid #e9e9e9;
	.title {
		font-weight: 600;
		font-size: 1.125em;
	}
	.count {
		font-weight: 600;
		font-size: 1.125em;
	}
}
.history_list {
	padding: 2em 0.913em 3em;
	margin-top: -2em;
	&.safari {
		padding-bottom: 5.813em;
	}
	.item {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1em;
		margin-bottom: 1em;
		color: black;
		border: 1px solid #e9e9e9;
		background-color: white;
		border-radius: 14px;
		box-shadow: 8.335px 8.271px 60px 0px rgba(0, 0, 0, 0.04);
	}
	.image {
		width: 27%;
		height: 5em;
		max-height: 5em;
	}
	.info {
		width: 61%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.barcode {
		display: block;
		max-width: 85%;
		font-weight: 600;
	}
	.name {
		font-size: 0.875em;
		margin-top: 0.5em;
		max-width: 85%;
		font-weight: 500;
	}
	.date_block {
		font-size: 0.875em;
		margin-top: 0.5em;
		color: #7c7c7c;
		font-weight: 500;
	}
	.time {
		padding-left: 0.813em;
	}
	.icon {
		position: absolute;
		width: 1.625em;
		height: 1.625em;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: contain;
		right: 0.8em;
		&.remove {
			bottom: 0.913em;
			background-image: url("@/assets/images/svg/search_history/remove.svg");
		}
		&.update {
			top: 0.913em;
			background-image: url("@/assets/images/svg/search_history/update.svg");
		}
	}
}
</style>
