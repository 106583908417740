<template>
	<a
		:href="product.link"
		target="_blank"
		class="product_item"
		:class="{ last: product.last }"
	>
		<!-- <div class="tag" :class="product.from"></div> -->
		<div class="free_delivery" v-if="product.free_delivery"></div>
		<div class="image">
			<img :src="product.thumbnail" :alt="product.title" />
		</div>
		<div class="description">
			<span class="source" v-if="product.source">{{ product.source }}</span>
			<span class="name">
				{{ getCuttedTitle }}
			</span>
			<div class="rating_container">
				<div class="rating">
					<div
						class="star"
						:key="number"
						v-for="number in rating"
						:class="{
							active:
								Math.round(product.rating) >= number && product.rating != null
						}"
					></div>
				</div>
			</div>
			<span class="price">£ {{ product.price }} </span>
		</div>
	</a>
</template>

<script>
export default {
	props: {
		product: {
			type: Object,
			required: true
		}
	},

	data() {
		return {
			rating: [1, 2, 3, 4, 5]
		};
	},

	computed: {
		getCuttedTitle() {
			return this.product.title.slice(0, 60);
		}
	},

	methods: {
		openResource() {
			this.$emit("openResource", this.product.link);
		}
	}
};
</script>

<style lang="scss" scoped>
.product_item {
	position: relative;
	width: 46%;
	margin: 0 2% 1em;
	padding: 0.8em;
	border: 1px solid #e9e9e9;
	border-radius: 12px;
	overflow: hidden;
	background-color: white;
	box-shadow: 8.335px 8.271px 60px 0px rgba(0, 0, 0, 0.04);
	transition: all 300ms ease;
	opacity: 1;
	&.show-enter-active,
	&.show-leave-active,
	&.show-move {
		opacity: 0;
	}
	// &.last {
	// 	border-color: last;
	// }
}
// .tag {
// 	position: absolute;
// 	top: 0.2em;
// 	left: 0.7em;
// 	width: 2.25em;
// 	height: 2.25em;
// 	background-repeat: no-repeat;
// 	background-position: 50%;
// 	background-size: contain;
// 	z-index: 9;
// 	&.shop {
// 		background-image: url("~@/assets/images/svg/resources/shop.svg");
// 	}
// 	&.advert {
// 		background-image: url("~@/assets/images/svg/resources/advert.svg");
// 	}
// 	&.amazon {
// 		background-image: url("~@/assets/images/svg/resources/amazon.svg");
// 	}
// 	&.ebay {
// 		background-image: url("~@/assets/images/svg/resources/ebay.svg");
// 	}
// }
.free_delivery {
	position: absolute;
	top: 0.4em;
	left: 0.7em;
	width: 1.7em;
	height: 1.7em;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: contain;
	background-image: url("~@/assets/images/svg/free_delivery.svg");
	z-index: 9;
}
.image {
	height: 6em;
}
.description {
	color: black;
	text-align: center;
	margin-top: 0.7em;
}
.source {
	display: inline-block;
	font-size: 0.613em;
	background-color: $green;
	padding: 0.5em 1em;
	margin-bottom: 0.813em;
	border-radius: 50px;
	letter-spacing: 0.05em;
	color: white;
	line-height: 1;
}
.name {
	display: block;
	font-size: 0.75em;
	font-weight: 500;
}
.rating_container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 0.5em;
}
.rating {
	display: flex;
	justify-content: center;
	margin: 0 -0.125em;
	.star {
		width: 0.938em;
		height: 0.938em;
		margin: 0 0.125em;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: contain;
		background-image: url("~@/assets/images/svg/star.svg");
		filter: brightness(0) opacity(0.15);
		&.active {
			filter: brightness(1) opacity(1);
		}
	}
}
.no_rating {
	width: 5.188em;
	height: 1.313em;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: contain;
	background-image: url("~@/assets/images/svg/no_rating.svg");
}
.price {
	display: block;
	margin-top: 0.5em;
	font-size: 1.1em;
	font-weight: 600;
}
</style>
