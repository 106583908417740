import { createStore } from "vuex";
import axios from "axios";
const version = "0.1.2";

// Create a new store instance.
export const store = createStore({
	state() {
		return {
			//requestsLeft: 0,
			header: "",
			searchHistory: {
				list: [],
				version: ""
			},
			products: {
				all: [],
				shop: [],
				advert: [],
				ebay: [],
				amazon: []
			}
		};
	},

	actions: {
		async getRequestsCount({ commit }) {
			let response = await axios.get(
				"https://nomera.sigma-studio.pp.ua/barcode/get_requests.php"
			);
			commit("SET_DATA", response.data.total_searches_left);
		}
	},

	mutations: {
		SET_DATA(state, data) {
			state.requestsLeft = data;
		},

		SET_HEADER_STATE(state, headerState) {
			state.header = headerState;
		},

		ADD_TO_HISTORY(state, data) {
			let add = true;
			state.searchHistory.list.map((item) => {
				if (item.search == data.search) {
					add = false;
				}
			});
			if (add && data.search) {
				state.searchHistory.list.unshift(data);
			}
		},

		REMOVE_FROM_HISTORY(state, search) {
			let index = state.searchHistory.list.findIndex(
				(item) => item.search == search
			);
			state.searchHistory.list.splice(index, 1);
		},

		initialiseSearchStorage(state) {
			if (localStorage.getItem("searchHistory")) {
				let searchHistory = JSON.parse(localStorage.getItem("searchHistory"));
				if (searchHistory.version == version) {
					state.searchHistory = searchHistory;
					// this.replaceState(
					// 	Object.assign(state.searchHistory.list, searchHistory.list)
					// );
				} else {
					state.searchHistory = {
						list: [],
						version: version
					};
					localStorage.setItem(
						"searchHistory",
						JSON.stringify(state.searchHistory)
					);
				}
			}
		},

		updateProducts(state, products) {
			for (let key in products) {
				state.products[key] = products[key];
			}
		}
	}
});
