<template>
	<div class="arrow_top" @click="scrollTop()"></div>
</template>

<script>
export default {
	mounted() {
		window.addEventListener("scroll", this.hideOnScroll);
	},

	beforeUnmount() {
		window.removeEventListener("scroll", this.hideOnScroll);
	},

	methods: {
		scrollTop() {
			window.scrollTo({ top: 0, behavior: "smooth" });
		},
		hideOnScroll() {
			const header = document.querySelector(".arrow_top");
			if (scrollY >= 1200) {
				header.classList.add("show");
			} else {
				header.classList.remove("show");
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.arrow_top {
	position: fixed;
	bottom: 2em;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 12px;
	width: 3.063em;
	height: 3.063em;
	cursor: pointer;
	right: 0;
	transform: translateX(100%);
	//border: 1px solid #e9e9e9;
	background-color: rgba($color: white, $alpha: 1);
	box-shadow: 8.335px 8.271px 60px 0px rgba(0, 0, 0, 0.16);
	transition: all 0.3s ease-in-out;
	&.show {
		right: 1em;
		transform: translateX(0);
	}
	&:after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(90deg);
		width: 1em;
		height: 1em;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: contain;
		background-image: url("@/assets/images/svg/arrow_black.svg");
	}
}
</style>
