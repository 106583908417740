<template>
	<transition-group name="show" tag="div" class="product_list">
		<product-item
			:key="product.link + index + selectedFilter"
			v-for="(product, index) in paginatedProducts"
			:product="product"
		/>
	</transition-group>
</template>

<script>
import ProductItem from "@/components/Products/ProductItem.vue";
export default {
	components: { ProductItem },

	props: {
		products: {
			type: Array,
			required: true
		},
		selectedFilter: {
			type: String,
			required: false
		}
	},

	data() {
		return {
			paginatedProducts: [],
			page: 1,
			productsPerPage: 10
		};
	},

	methods: {
		handleScroll() {
			let products = document.querySelectorAll(".product_list .product_item");
			let lastProduct = products[products.length - 1];
			let lastProductPosition =
				lastProduct.getBoundingClientRect().top +
				window.scrollY -
				window.innerHeight;
			if (window.scrollY >= lastProductPosition - 200) {
				this.loadProducts();
			}
		},

		loadProducts() {
			this.page++;
			this.paginatedProducts = this.products.slice(
				0,
				this.productsPerPage * this.page
			);
		},

		updatePagination() {
			this.page = 1;
			this.loadProducts();
		}
	},

	mounted() {
		window.addEventListener("scroll", this.handleScroll);
		this.loadProducts();
	},

	unmounted() {
		window.removeEventListener("scroll", this.handleScroll);
	}
};
</script>

<style lang="scss" scoped>
.product_list {
	display: flex;
	flex-wrap: wrap;
	// margin: 0 -0.5em;
}
</style>
