<template>
	<transition name="show">
		<div class="searching" v-show="show">
			<div class="animation">
				<lottie-animation
					ref="anim"
					:animationData="require('@/assets/lottie/searching2.json')"
					:loop="true"
					:autoPlay="true"
				/>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	data() {
		return {
			show: false
		};
	},

	methods: {
		showAnimation() {
			this.show = true;
			this.playAnimation();
		},

		hideAnimation() {
			this.show = false;
			this.stopAnimation();
		},

		stopAnimation() {
			this.$refs.anim.stop();
		},

		playAnimation() {
			this.$refs.anim.play();
		}
	}
};
</script>

<style lang="scss" scoped>
.searching {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	opacity: 1;
	transition: $defaultTransition;
	&.show-enter-active,
	&.show-leave-active {
		opacity: 0;
	}
	.animation {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		:deep() {
			svg {
				width: 130% !important;
				height: 130% !important;
				filter: invert(1);
			}
		}
	}
}
</style>
